export interface SlideData {
  title: string;
  template: SlideTemplate;
  modules: SlideModule[];
}

export interface SlideTemplate {
  templateType: TemplateType;
  moduleCount: number;
}

export enum TemplateType {
  NOT_CONFIGURED = 'NOT_CONFIGURED',
  TITLE_SLIDE = 'TITLE_SLIDE',
  ONE_FULL = 'ONE_FULL',
  ONE_FULL_GEO = 'ONE_FULL_GEO',
  TWO_COLUMNS = 'TWO_COLUMNS',
  FOUR_GRID = 'FOUR_GRID',
}

export interface SlideModule {
  moduleType: ModuleType;
  contentType: ModuleContentType;
  template?: ModuleTemplate;
  header?: string;
  textContent?: string[];
  imgUrl?: string;
  caption?: string;
}

export enum ModuleType {
  NOT_CONFIGURED = 'NOT_CONFIGURED',
  COUNTRY_DATA = 'COUNTRY_DATA',
  EQUIPMENT_SPEC = 'EQUIPMENT_SPEC',
  MAP = 'MAP',
  CUSTOM_IMAGE = 'CUSTOM_IMAGE',
}

export enum ModuleContentType {
  NOT_CONFIGURED = 'NOT_CONFIGURED',
  TEXT_HEADER = 'TEXT_HEADER',
  TEXT_SUBHEADER = 'TEXT_SUBHEADER',
  TEXT_BULLETS = 'TEXT_BULLETS',
  TEXT_FREEFORM = 'TEXT_FREEFORM',
  IMAGE = 'IMAGE',
  IMAGE_WITH_CAPTION = 'IMAGE_WITH_CAPTION',
}

export enum ModuleTemplate {
  ONE_FULL = 'ONE_FULL',
  ONE_FULL_HALF_HEIGHT = 'ONE_FULL_HALF_HEIGHT',
  TWO_COLUMNS = 'TWO_COLUMNS',
  FOUR_GRID = 'FOUR_GRID',
}

export interface SlideTheme {
  color: string;
  'background-color': string;
}

export interface Classification {
  label: string;
  location: BannerLocation;
  // eslint-disable-next-line
  theme: any;
}

export enum BannerLocation {
  TOP = 'TOP',
  BOTTOM = 'BOTTOM',
  BOTH = 'BOTH',
}

export interface Modal {
  show: boolean;
  targetSlideId?: number;
  targetModule?: number;
}

export interface State {
  auth: {
    loginStatus: boolean;
  };
  unsavedChanges: boolean;
  packageData: {
    name: string;
    template: SlideTemplate;
    classification: Classification;
    slideTheme: SlideTheme;
  };
  modals: {
    [key: string]: Modal;
  };
  slideData: SlideData[];
}
