import Vue from 'vue';
import Vuex from 'vuex';

import { TemplateType, BannerLocation, ModuleType, ModuleContentType, State, SlideModule } from '../types/types';

Vue.use(Vuex);

export default new Vuex.Store<State>({
  state: {
    auth: {
      loginStatus: false,
    },
    unsavedChanges: false,
    packageData: {
      name: '',
      template: {
        templateType: TemplateType.NOT_CONFIGURED,
        moduleCount: 0,
      },
      classification: {
        label: 'UNCLASSIFIED//FOR EXERCISE USE ONLY',
        location: BannerLocation.BOTH,
        theme: {
          color: '#FFFFFFFF',
          'background-color': '#000000FF',
          'line-height': '10px',
          'font-weight': 600,
          'max-height': '35px',
        },
      },
      slideTheme: {
        color: '#000000FF',
        'background-color': '#FFFFFFFF',
      },
    },
    modals: {
      classificationModal: {
        show: false,
      },
      geoModal: {
        show: false,
      },
    },
    slideData: [
      {
        title: 'Title slide',
        template: {
          templateType: TemplateType.TITLE_SLIDE,
          moduleCount: 4,
        },
        modules: [
          {
            moduleType: ModuleType.COUNTRY_DATA,
            contentType: ModuleContentType.IMAGE,
            imgUrl: '/img/flag-ukraine.jpg',
          },
          {
            moduleType: ModuleType.COUNTRY_DATA,
            contentType: ModuleContentType.TEXT_HEADER,
            textContent: ['UKRAINE COUNTRY BRIEF​'],
          },
          {
            moduleType: ModuleType.COUNTRY_DATA,
            contentType: ModuleContentType.TEXT_SUBHEADER,
            textContent: ['Presentation Date:​ June 4th, 2021'],
          },
          {
            moduleType: ModuleType.COUNTRY_DATA,
            contentType: ModuleContentType.TEXT_SUBHEADER,
            textContent: ['Information Cut Off Date: May 25th, 2021​'],
          },
        ],
      },
      {
        title: 'Orientation - Macro',
        template: {
          templateType: TemplateType.ONE_FULL,
          moduleCount: 1,
        },
        modules: [
          {
            moduleType: ModuleType.COUNTRY_DATA,
            contentType: ModuleContentType.IMAGE,
            imgUrl: '/img/ukraine-macro.jpg',
          },
        ],
      },
      {
        title: 'Orientation - Micro',
        template: {
          templateType: TemplateType.ONE_FULL,
          moduleCount: 1,
        },
        modules: [
          {
            moduleType: ModuleType.COUNTRY_DATA,
            contentType: ModuleContentType.IMAGE,
            imgUrl: '/img/ukraine-micro.jpg',
          },
        ],
      },
      {
        title: 'Executive Summary',
        template: {
          templateType: TemplateType.FOUR_GRID,
          moduleCount: 4,
        },
        modules: [
          {
            moduleType: ModuleType.COUNTRY_DATA,
            contentType: ModuleContentType.TEXT_BULLETS,
            header: 'Leadership overview',
            textContent: [
              'Executive branch (chief of state): President Volodymyr ZELENSKYY (since 20 May 2019)',
              'Executive branch (head of government): Prime Minister Denys SHMYHAL (since 4 March 2020)',
              'Government type: semi-presidential republic',
              'Legal system: civil law system; judicial review of legislative acts',
              'Capital (name): Kyiv (Kiev)',
              'Capital (geographic coordinates): 50 26 N, 30 31 E',
            ],
          },
          {
            moduleType: ModuleType.COUNTRY_DATA,
            contentType: ModuleContentType.IMAGE_WITH_CAPTION,
            imgUrl: '/img/president-volodymyr-zelenskyy.jpg',
            caption: 'President Volodymyr ZELENSKYY​​',
          },
          {
            moduleType: ModuleType.COUNTRY_DATA,
            contentType: ModuleContentType.TEXT_BULLETS,
            header: 'Population & Society',
            textContent: [
              'Population: 43,745,640 (July 2021 est.)',
              'Ethnic groups: Ukrainian 77.8%, Russian 17.3%, Belarusian 0.6%, Moldovan 0.5%, Crimean Tatar 0.5%, Bulgarian 0.4%, Hungarian 0.3%, Romanian 0.3%, Polish 0.3%, Jewish 0.2%, other 1.8% (2001 est.)',
              'Languages: Ukrainian (official) 67.5%, Russian (regional language) 29.6%, other (includes small Crimean Tatar-, Moldovan/Romanian-, and Hungarian-speaking minorities) 2.9% (2001 est.)',
              'Religions: Orthodox (includes&nbsp;the Orthodox Church of Ukraine (OCU) and the&nbsp;Ukrainian Orthodox - Moscow Patriarchate (UOC-MP)), Ukrainian Greek Catholic, Roman Catholic, Protestant, Muslim, Jewish (2013 est.)',
              'Median age (total): 41.2 years',
              "Population distribution: densest settlement in the eastern (Donbas) and western regions; noteable concentrations in and around major urban areas of Kyiv, Kharkiv, Donets'k, Dnipropetrovs'k, and Odesa",
            ],
          },
          {
            moduleType: ModuleType.COUNTRY_DATA,
            contentType: ModuleContentType.TEXT_BULLETS,
            header: 'Geography',
            textContent: [
              'Location: Eastern Europe, bordering the Black Sea, between Poland, Romania, and Moldova in the west and Russia in the east',
              'Geographic coordinates: 49 00 N, 32 00 E',
              'Area (total): 603,550 sq km',
              'Area - comparative: almost four times the size of Georgia; slightly smaller than Texas',
              'Natural resources: iron ore, coal, manganese, natural gas, oil, salt, sulfur, graphite, titanium, magnesium, kaolin, nickel, mercury, timber, arable land',
              'Geography - note: strategic position at the crossroads between Europe and Asia; second-largest country in Europe after Russia',
              'Land boundaries (border countries): Belarus 1111 km, Hungary 128 km, Moldova 1202 km, Poland 535 km, Romania 601 km, Russia 1944 km, Slovakia 97 km',
            ],
          },
        ],
      },
      {
        title: 'Geography and Climate',
        template: {
          templateType: TemplateType.FOUR_GRID,
          moduleCount: 4,
        },
        modules: [
          {
            moduleType: ModuleType.COUNTRY_DATA,
            contentType: ModuleContentType.TEXT_BULLETS,
            header: 'Geographic overview',
            textContent: [
              'Geographic coordinates: 49 00 N, 32 00 E',
              'Location: Eastern Europe, bordering the Black Sea, between Poland, Romania, and Moldova in the west and Russia in the east',
              'Area (total): 603,550 sq km',
              'Coastline: 2,782 km ',
            ],
          },
          {
            moduleType: ModuleType.COUNTRY_DATA,
            contentType: ModuleContentType.TEXT_BULLETS,
            header: 'Climate & Terrain',
            textContent: [
              'Climate: temperate continental; Mediterranean only on the southern Crimean coast; precipitation disproportionately distributed, highest in west and north, lesser in east and southeast; winters vary from cool along the Black Sea to cold farther inland; warm summers across the greater part of the country, hot in the south',
              'Terrain: mostly fertile plains (steppes) and plateaus, with mountains found only in the west (the Carpathians) or in the extreme south of the Crimean Peninsula',
              "Environment - current issues: air and water pollution; land degradation; solid waste management; biodiversity loss; deforestation; radiation contamination in the northeast from 1986 accident at Chornobyl' Nuclear Power Plant",
            ],
          },
          {
            moduleType: ModuleType.COUNTRY_DATA,
            contentType: ModuleContentType.TEXT_BULLETS,
            header: 'Borders & International Agreements',
            textContent: [
              'Land boundaries (total): 5,618 km',
              'Land boundaries (border countries): Belarus 1111 km, Hungary 128 km, Moldova 1202 km, Poland 535 km, Romania 601 km, Russia 1944 km, Slovakia 97 km',
              'Environment - international agreements (party to): Air Pollution, Air Pollution-Nitrogen Oxides, Air Pollution-Sulfur 85, Antarctic-Environmental Protocol, Antarctic-Marine Living Resources, Antarctic Treaty, Biodiversity, Climate Change, Climate Change-Kyoto Protocol, Desertification, Endangered Species, Environmental Modification, Hazardous Wastes, Law of the Sea, Marine Dumping, Ozone Layer Protection, Ship Pollution, Wetlands',
              'Environment - international agreements (signed, but not ratified): Air Pollution-Persistent Organic Pollutants, Air Pollution-Sulfur 94, Air Pollution-Volatile Organic Compounds',
              'Geography - note: strategic position at the crossroads between Europe and Asia; second-largest country in Europe after Russia',
            ],
          },
          {
            moduleType: ModuleType.COUNTRY_DATA,
            contentType: ModuleContentType.TEXT_BULLETS,
            header: 'Electricity & Crude Oil',
            textContent: [
              'Electricity - production: 153.6 billion kWh (2016 est.)',
              'Electricity - exports: 3.591 billion kWh (2015 est.)',
              'Electricity - imports: 77 million kWh (2016 est.)',
              'Electricity - from fossil fuels: 65% of total installed capacity (2016 est.)',
              'Crude oil - production: 32,000 bbl/day (2018 est.)',
              'Crude oil - exports: 413 bbl/day (2015 est.)',
              'Crude oil - imports: 4,720 bbl/day (2015 est.)',
              'Crude oil - proved reserves: 395 million bbl (1 January 2018 est.)',
            ],
          },
        ],
      },
      {
        title: 'Background & History',
        template: {
          templateType: TemplateType.ONE_FULL,
          moduleCount: 1,
        },
        modules: [
          {
            moduleType: ModuleType.COUNTRY_DATA,
            contentType: ModuleContentType.TEXT_FREEFORM,
            textContent: [
              'Background: Ukraine was the center of the first eastern Slavic state, Kyivan Rus, which during the 10th and 11th centuries was the largest and most powerful state in Europe. During the latter part of the 18th century, most Ukrainian ethnographic territory was absorbed by the Russian Empire. Following the collapse of czarist Russia in 1917, Ukraine achieved a short-lived period of independence , but was reconquered and endured a brutal Soviet rule that engineered two forced famines in which over 8 million died. In October 2012, Ukraine held Rada elections, widely criticized by Western observers as flawed due to use of government resources to favor ruling party candidates, interference with media access, and harassment of opposition candidates.​',
              "President YANUKOVYCH's backtracking on a trade and cooperation agreement with the EU in November 2013 - in favor of closer economic ties with Russia - and subsequent use of force against students, civil society activists, and other civilians in favor of the agreement led to a three-month protest occupation of Kyiv's central square. The government's use of violence to break up the protest camp in February 2014 led to all out pitched battles, scores of deaths, international condemnation, a failed political deal, and the president's abrupt departure for Russia. Two weeks later, a «referendum» was held regarding the integration of Crimea into the Russian Federation. In response to Russia's illegal annexation of Crimea, 100 members of the UN passed UNGA resolution 68/262, rejecting the «referendum» as baseless and invalid and confirming the sovereignty, political independence, unity, and territorial integrity of Ukraine.​",
              "In mid-2014, Russia began supplying proxies in two of Ukraine's eastern provinces with manpower, funding, and materiel driving an armed conflict with the Ukrainian Government that continues to this day. Representatives from Ukraine, Russia, and the unrecognized Russian proxy republics signed the Minsk Protocol and Memorandum in September 2014 to end the conflict. In a renewed attempt to alleviate ongoing clashes, leaders of Ukraine, Russia, France, and Germany negotiated a follow-on Package of Measures in February 2015 to implement the Minsk agreements. Representatives from Ukraine, Russia, the unrecognized Russian proxy republics, and the Organization for Security and Cooperation in Europe also meet regularly to facilitate implementation of the peace deal.​ More than 13,000 civilians have been killed or wounded as a result of the Russian intervention in eastern Ukraine.​",
              "In mid-2014, Russia began supplying proxies in two of Ukraine's eastern provinces with manpower, funding, and materiel driving an armed conflict with the Ukrainian Government that continues to this day. Representatives from Ukraine, Russia, and the unrecognized Russian proxy republics signed the Minsk Protocol and Memorandum in September 2014 to end the conflict. In a renewed attempt to alleviate ongoing clashes, leaders of Ukraine, Russia, France, and Germany negotiated a follow-on Package of Measures in February 2015 to implement the Minsk agreements. Representatives from Ukraine, Russia, the unrecognized Russian proxy republics, and the Organization for Security and Cooperation in Europe also meet regularly to facilitate implementation of the peace deal.​ More than 13,000 civilians have been killed or wounded as a result of the Russian intervention in eastern Ukraine.​",
            ],
          },
        ],
      },
      {
        title: 'Political',
        template: {
          templateType: TemplateType.FOUR_GRID,
          moduleCount: 4,
        },
        modules: [
          {
            moduleType: ModuleType.COUNTRY_DATA,
            contentType: ModuleContentType.TEXT_BULLETS,
            header: 'Political structure',
            textContent: [
              'Government type: semi-presidential republic',
              'Capital (name): Kyiv (Kiev)',
              'Capital (geographic coordinates): 50 26 N, 30 31 E',
              'Capital (time difference): UTC+2 (7 hours ahead of Washington, DC, during Standard Time)',
              "Administrative divisions: 24 provinces (oblasti, singular - oblast'), 1 autonomous republic* (avtonomna respublika), and 2 municipalities** (mista, singular - misto) with oblast status; Cherkasy, Chernihiv, Chernivtsi, Crimea or Avtonomna Respublika Krym* (Simferopol), Dnipropetrovsk (Dnipro), Donetsk, Ivano-Frankivsk, Kharkiv, Kherson, Khmelnytskyi, Kirovohrad (Kropyvnytskyi), Kyiv**, Kyiv, Luhansk, Lviv, Mykolaiv, Odesa, Poltava, Rivne, Sevastopol**, Sumy, Ternopil, Vinnytsia, Volyn (Lutsk), Zakarpattia (Uzhhorod), Zaporizhzhia, Zhytomyr",
            ],
          },
          {
            moduleType: ModuleType.COUNTRY_DATA,
            contentType: ModuleContentType.TEXT_BULLETS,
            header: 'Political parties and leaders',
            textContent: [
              'Batkivshchyna (Fatherland) [Yuliya TYMOSHENKO]',
              'European Solidarity (BPP-Solidarity) [Petro POROSHENKO]​',
              'Holos (Voice) [Sviatoslav VAKARCHUK]​',
              'Opposition Bloc or OB [Evgeny MURAYEV]​',
              'Opposition Platform-For Life [Yuriy BOYKO, Vadim RABINOVICH]​',
              'Radical Party [Oleh LYASHKO]​',
              'Samopomich (Self Reliance) [Andriy SADOVYY]​',
              'Servant of the People [Oleksandr KORNIENKO]​',
              'Svoboda (Freedom) [Oleh TYAHNYBOK]',
              'Batkivshchyna (Fatherland) [Yuliya TYMOSHENKO]',
              'European Solidarity (BPP-Solidarity) [Petro POROSHENKO]​',
            ],
          },
          {
            moduleType: ModuleType.COUNTRY_DATA,
            contentType: ModuleContentType.TEXT_FREEFORM,
            header: 'International disputes',
            textContent: [
              "Disputes - international: 1997 boundary delimitation treaty with Belarus remains unratified due to unresolved financial claims, stalling demarcation and reducing border security; delimitation of land boundary with Russia is complete and demarcation began in 2012; the dispute over the boundary between Russia and Ukraine through the Kerch Strait and Sea of Azov is suspended due to the occupation of Crimea by Russia; Ukraine and Moldova signed an agreement officially delimiting their border in 1999, but the border has not been demarcated due to Moldova's difficulties with the break-away region of Transnistria; Moldova and Ukraine operate joint customs posts to monitor transit of people and commodities through Moldova's Transnistria Region, which remains under the auspices of an Organization for Security and Cooperation in Europe-mandated peacekeeping mission comprised of Moldovan, Transnistrian, Russian, and Ukrainian troops; the ICJ ruled largely in favor of Romania in its dispute submitted in 2004 over Ukrainian-administered Zmiyinyy/Serpilor (Snake) Island and Black Sea maritime boundary delimitation.",
            ],
          },
          {
            moduleType: ModuleType.COUNTRY_DATA,
            contentType: ModuleContentType.NOT_CONFIGURED,
          },
        ],
      },
      {
        title: 'Military',
        template: {
          templateType: TemplateType.TWO_COLUMNS,
          moduleCount: 1,
        },
        modules: [
          {
            moduleType: ModuleType.COUNTRY_DATA,
            contentType: ModuleContentType.TEXT_FREEFORM,
            textContent: [
              'Military expenditures (Military Expenditures 2019): 3.9% of GDP (2019)',
              "Military and security forces: Armed Forces of Ukraine (Zbroyni Syly Ukrayiny, ZSU): Ground Forces (Sukhoputni Viys'ka), Naval Forces (Viys'kovo-Mors'ki Syly, VMS), Air Forces (Povitryani Syly, PS), Air Assault Forces (Desantno-shturmovi Viyska, DShV);  Ministry of Internal Affairs: National Guard of Ukraine, State Border Guard Service of Ukraine (includes Maritime Border Guard) (2020)",
              'Military and security service personnel strengths: size estimates for the Armed Forces of Ukraine (Zbroyni Syly Ukrayiny, ZSU) vary; approximately 215,000 active troops (160,000 Army, including Airborne/Air Assault Forces; 13,000 Navy; 42,000 Air Force); est. 50,000 National Guard (2019 est.)',
              'Military equipment inventories and acquisitions: the Ukrainian military is equipped mostly with older Russian and Soviet-era weapons systems; since 2010, it has imported limited quantities of weapons from several European countries, as well as Canada, the US, and the United Arab Emirates; Ukraine has a broad defense industry capable of building Soviet-era land systems and maintaining and upgrading Soviet-era combat aircraft, as well as missile and air defense systems (2019 est.)',
              'Military deployments: 250 Democratic Republic of the Congo (MONUSCO); contributes about 550 troops to the Lithuania, Poland, and Ukraine joint military brigade (LITPOLUKRBRIG), which was established in 2014; the brigade is headquartered in Warsaw and is comprised of an international staff, three battalions, and specialized units (2020)',
              'Military service age and obligation: 20-27 years of age for compulsory military service; conscript service obligation is 12 months (2019)',
              "Military - note: the Ukrainian military's primary concern is Russia's material support for armed separatist forces in the eastern provinces of Donetsk and Luhansk where the conflict has become stalemated; since the cease-fire of October 2019, Ukrainian military casualties along the front line have fallen significantly despite continued sporadic exchanges of fire through 2020 and into 2021",
            ],
          },
          {
            moduleType: ModuleType.COUNTRY_DATA,
            contentType: ModuleContentType.IMAGE_WITH_CAPTION,
            imgUrl: '/img/general-staff-of-the-ukrainian-armed-forces.png',
            caption: 'General Staff of the Armed Forces of Ukraine logo​​',
          },
        ],
      },
      {
        title: 'Equipment - 1L119 Nebo-SVU',
        template: {
          templateType: TemplateType.TWO_COLUMNS,
          moduleCount: 1,
        },
        modules: [
          {
            moduleType: ModuleType.NOT_CONFIGURED,
            contentType: ModuleContentType.NOT_CONFIGURED,
          },
          {
            moduleType: ModuleType.EQUIPMENT_SPEC,
            contentType: ModuleContentType.IMAGE_WITH_CAPTION,
            imgUrl: '/img/nebo-svu.png',
            caption: '1L119 Nebo-SVU',
          },
        ],
      },
    ],
  },
  getters: {
    /*
      Call these getters by using the getters function: this.$store.getters.getFunctionName
      Getters can also be passed arguments:
        getEleById: (state) => (id) => {
          return state.elements.find(element => element.id === id)
        }
    */
    getAuthStatus: (state) => {
      return state.auth.loginStatus;
    },
    getSlideData: (state) => {
      return state.slideData;
    },
    getSlideTheme: (state) => {
      return state.packageData.slideTheme;
    },
    getClassification: (state) => {
      return state.packageData.classification;
    },
    getUnsavedChanges: (state) => {
      return state.unsavedChanges;
    },
    getModalData: (state) => {
      return state.modals;
    },
  },
  actions: {
    /*
      Actions are functions that don’t change the state themselves.
      Instead, they commit mutations after performing some logic (which is often asynchronous).  
      Call these actions using the dispatch function: this.$store.dispatch('actionName')
    */
    loginSuccessful(context, timestamp) {
      window.localStorage.setItem('raptr-access', timestamp);
      context.commit('setLoginStatus', true);
      return true;
    },
    setLoginStatus(context, value: boolean) {
      context.commit('setLoginStatus', value);
    },
    logout(context) {
      window.localStorage.removeItem('raptr-access');
      context.commit('logout');
    },
    addNewslide(context, slide) {
      context.commit('addNewslide', slide);
    },
    toggleModalVisibility(context, { targetModal, setTo }: { targetModal: string; setTo?: boolean }) {
      if (targetModal in this.state.modals) {
        context.commit('toggleModalVisibility', { targetModal, setTo });
      }
    },
    setClassificationTheme(context, theme) {
      context.commit('setClassificationTheme', theme);
    },
    saveChanges(context) {
      context.commit('saveChanges');
    },
    updateModuleData(
      context,
      {
        targetSlideId,
        targetModuleId,
        moduleData,
      }: { targetSlideId: number; targetModuleId: number; moduleData: SlideModule }
    ) {
      context.commit('updateModuleData', { targetSlideId, targetModuleId, moduleData });
    },
  },
  mutations: {
    /*
      Mutations must be synchronous. Mutations change the state. We should avoid calling mutations directly from within the code,
      instead, call the relevant actions instead. Let the actions commit the mutations after verification.
      Call mutations using the commit function: this.$store.commit('mutationName', value)
    */
    setLoginStatus(state, newValue) {
      state.auth.loginStatus = newValue;
    },
    logout(state) {
      state.auth.loginStatus = false;
    },
    addNewslide(state, slide) {
      state.slideData.push(slide);
    },
    setPackageName(state, name) {
      state.packageData.name = name;
    },
    setPackageTemplate(state, template) {
      state.packageData.template = template;
    },
    setPackageClassification(state, classification) {
      state.packageData.classification = classification;
    },
    setClassificationTheme(state, theme) {
      for (const [key, value] of Object.entries(theme)) {
        state.packageData.classification.theme[key] = value;
      }
    },
    toggleModalVisibility(state, { targetModal, targetVisibility }) {
      if (targetVisibility) {
        state.modals[targetModal].show = targetVisibility;
      } else {
        state.modals[targetModal].show = !state.modals[targetModal].show;
      }
    },
    saveChanges(state) {
      // this mutation should save changes to a package
      state.unsavedChanges = !state.unsavedChanges;
    },
    updateModuleData(state, { targetSlideId, targetModuleId, moduleData }) {
      state.slideData[targetSlideId].modules[targetModuleId] = moduleData;
    },
  },
  modules: {},
});
