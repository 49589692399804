import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import colors from 'vuetify/lib/util/colors';

Vue.use(Vuetify);

const vuetify = new Vuetify({
  options: {
    customProperties: true,
  },
  rtl: false,
  theme: {
    dark: true,
    themes: {
      dark: {
        primary: '#fca311',
        background: '#1E1E1E',
        backgroundLight: '#303030',
        accent: '#fca311',
        secondary: colors.blueGrey.darken1,
        success: colors.green,
        info: colors.blue,
        warning: colors.orange,
        error: colors.red.accent2,
        placeholder: colors.grey.lighten2,
      },
      light: {
        primary: colors.blueGrey.darken1,
        background: '#FFFFFF',
        accent: '#fca311',
        secondary: colors.blueGrey.lighten3,
        success: colors.green,
        info: colors.blue,
        warning: colors.orange,
        error: colors.red.accent2,
        placeholder: colors.grey.lighten2,
      },
    },
  },
});

export default vuetify;
