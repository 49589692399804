








































































import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class Home extends Vue {
  mounted(): void {
    if (window.localStorage.getItem('raptr-access')) {
      this.$store.dispatch('setLoginStatus', true);
    }
  }

  get authenticated(): boolean {
    return this.$store.getters.getAuthStatus;
  }

  toggleDarkMode(): void {
    this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
  }
}
