import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import store from '../store';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
  },
  {
    path: '/logout',
    name: 'Logout',
    component: () => import(/* webpackChunkName: "login" */ '../views/Logout.vue'),
  },
  {
    path: '/editor',
    name: 'Editor',
    component: () => import(/* webpackChunkName: "editor" */ '../views/Editor.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () => import(/* webpackChunkName: "profile" */ '../views/Profile.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/geo',
    name: 'Geo',
    component: () => import(/* webpackChunkName: "geo" */ '../components/GeoComponent.vue'),
    meta: {
      requiresAuth: true,
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (to.name === 'Logout') {
    await store.dispatch('logout');
    next({ name: 'Login' });
  } else if (to.meta?.requiresAuth) {
    const timestamp = window.localStorage.getItem('raptr-access');

    if (timestamp) {
      const then = parseInt(timestamp);
      const now = Date.now();
      const diff = Math.abs(now - then);

      if (diff < 86400000) {
        next();
      } else {
        // Route requires auth, we have auth, auth is not under 24h: clear auth and send to auth page
        await store.dispatch('logout');
        next({ name: 'Login' });
      }
    } else {
      // Route requires auth, we do not have auth: send to auth page
      await store.dispatch('logout');
      next({ name: 'Login' });
    }
  } else {
    // Route does not require auth: proceed to route
    next();
  }
});

export default router;
